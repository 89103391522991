<template>
  <v-row class="product-checkout" justify="center" no-gutters>
    <v-col cols="12" class="d-none">
      <slot name="breadcrumb"></slot>
    </v-col>
    <v-col cols="12">
      <slot name="progress-mobile"></slot>
    </v-col>
    <v-col cols="12">
      <v-row
        no-gutters
        class="product-content pt-md-10 flex-column-reverse flex-md-row"
      >
        <v-col
          cols="12"
          md="8"
          class="product-main"
          :class="{ 'product-main--spaced': $route.name === 'booking-form' }"
        >
          <slot></slot>
        </v-col>
        <v-col cols="12" md="4" class="product-side">
          <v-row no-gutters class="sticky side-info">
            <v-col cols="12" class="d-none d-md-flex side-info-progress">
              <slot name="progress"></slot>
            </v-col>
            <v-col cols="12">
              <slot name="summary"></slot>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CheckoutLayout',
};
</script>

<style lang="scss" scoped>
.product {
  &-checkout div {
    z-index: 1;
  }

  &-main {
    padding-right: 60px !important;

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      padding: 0px !important;

      &--spaced {
        padding: 0 20px !important;
      }
    }
  }
}
.side-info > div {
  flex-basis: 100%;
}
</style>
